import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import ToggleBar from '../../common/Util'
function MyFile() {


  useEffect(()=>{
    ToggleBar()
},[])

    return (
        <>
         <>
  <div className="page-wrapper  my-files-page position-relative overflow-hidden">
    <div className="position-sticky p-0 shadow z-index-1 bg-white px-sm-4 px-3">
      <div className="row mr-lg-0">
        <div className="col-md-12">
          <div className="navbar-custom   position-relative d-flex align-items-center">
            <Link to="/" className="d-md-none mr-3"><img src="assets/images/logo-sm.png" className="img-fluid logo-mob-icon" alt="img" /></Link>
            <button type="button" className="top-togglebar bg-transparent border-0">
              <img src="assets/images/menu.svg" className="img-fluid" alt="img" />
            </button>
            <div className="theme-custom-select position-relative ml-3 diable-enable-select border-0 brand-dropdown">
              <select className="form-control  border-0">
                <option className="py-4" value="alert type">Royal Canin</option>
                <option className="py-4" value="alert">Zevo</option>
              </select>
            </div>
            <div className="ml-auto">
              <ul className="list-unstyled mb-0 ">
                <li className="list-inline-item mx-2 d-sm-inline-block d-none"><img src="assets/images/royal-chain.jpg" className="img-fluid" alt="royal" /></li>
                <li className="list-inline-item ml-2">
                  <div className="dropdown  profile-detail py-1 pr-sm-3">
                    <a className="dropdown-toggle mr-2" href="#!" id="dropdown03" data-toggle="dropdown"  aria-expanded="true">
                      <img src="assets/images/user.jpg" className="img-fluid rounded-circle user-name mx-2" alt="img" />
                      <span className="d-none d-sm-inline-block pr-2 font-poppins">Amella</span>
                    </a>
                    <div className="dropdown-menu d-none " aria-labelledby="dropdown03">
                      {/*  <a class="dropdown-item mb-1" href="#"><img src="images/user.svg" class="img-fluid mr-2" alt="img"> Profile</a>
                                       <a class="dropdown-item mb-1" href="#"><img src="images/wallet.svg" class="img-fluid mr-2" alt="img"> My Wallet</a>
                                       <a class="dropdown-item mb-1" href="#"> <img src="images/settings.svg" class="img-fluid mr-2" alt="img">Settings</a>
                                       <a class="dropdown-item mb-1" href="#"> <img src="images/lock-p.svg" class="img-fluid mr-2" alt="img">Lock screen</a>
                                       <div class="dropdown-divider"></div>
                                       <a class="dropdown-item" href=""><img src="images/logout.svg" class="img-fluid mr-2" alt="img"> Logout</a> */}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content-tab p-sm-4 p-3 overflow-auto">
      <div className="row mb-3 align-items-center">
        <div className="col">
          <ul className="breadcrumb  pl-md-3 pl-0 list-unstyled mb-0 bg-transparent">
            <li className="h5 mb-0 font-poppins"><a href="#!">Royal Canin</a></li>
            <li><img src="assets/images/right-arrow.svg" className="img-fluid breadcrumb-arrow" alt="arrow" /></li>
            <li className="h5 mb-0 font-poppins"><a href="#!" className="font-weight-semibold">My Files</a></li>
          </ul>
        </div>
        <div className="col-auto text-right">
          <a className="btn btn-dark theme-btn py-2 px-4" href="#!" data-toggle="modal" data-target="#staticBackdrop">
            <img src="assets/images/icon-upload.svg" className="w-16 font-poppins font-weight-medium mr-2" alt="filter" />Upload File
          </a>
        </div>
      </div>
      <div className="container-sm px-0">
        <div className="row mb-lg-4 pb-3">
          <div className="col-sm-6 col-lg-3  mb-lg-0 mb-4 pb-lg-0 pb-3 d-flex">
            <div className="bg-white shadow p-sm-4 p-3 rounded d-flex flex-column h-auto w-100">
              <figure className="files-folder-icon blue-bg d-flex align-items-center justify-content-center rounded-circle">
                <img src="assets/images/folder-white.svg" className="img-fluid" alt="folder-icon" />
              </figure>
              <div >
                <h3 className="mb-1 font-weight-semibold blue-color">4Sight File</h3>
                <div className="d-flex align-items-center">
                  <p className="font-poppins text-muted mt-3 mb-0">Jan 20, 2021</p>
                  <p className="font-poppins mt-3 mb-0 font-weight-bold text-dark-80 ml-auto">780 MB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-lg-0 mb-4 pb-lg-0 pb-3 d-flex">
            <div className="bg-white shadow  p-sm-4 p-3 rounded  d-flex flex-column h-auto w-100">
              <figure className="files-folder-icon pink-bg d-flex align-items-center justify-content-center rounded-circle">
                <img src="assets/images/folder-white.svg" className="img-fluid" alt="folder-icon" />
              </figure>
              <div >
                <h3 className="mb-1  font-weight-semibold pink-color">4Sight File</h3>
                <div className="d-flex align-items-center">
                  <p className="font-poppins text-muted mt-3 mb-0">Jan 20, 2021</p>
                  <p className="font-poppins mt-3 mb-0 font-weight-bold text-dark-80 ml-auto">780 MB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-lg-0 mb-4 pb-lg-0 pb-3 d-flex">
            <div className="bg-white shadow  p-sm-4 p-3 rounded  d-flex flex-column h-auto w-100">
              <figure className="files-folder-icon green-bg d-flex align-items-center justify-content-center rounded-circle">
                <img src="assets/images/folder-white.svg" className="img-fluid" alt="folder-icon" />
              </figure>
              <div >
                <h3 className="mb-1  font-weight-semibold green-color">4Sight File</h3>
                <div className="d-flex align-items-center">
                  <p className="font-poppins text-muted mt-3 mb-0">Jan 20, 2021</p>
                  <p className="font-poppins mt-3 mb-0 font-weight-bold text-dark-80 ml-auto">780 MB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-lg-0 mb-4 pb-lg-0 pb-3 d-flex">
            <div className="bg-white shadow  p-sm-4 p-3 rounded  d-flex flex-column  h-auto w-100">
              <figure className="files-folder-icon red-bg d-flex align-items-center justify-content-center rounded-circle">
                <img src="assets/images/folder-white.svg" className="img-fluid" alt="folder-icon" />
              </figure>
              <div >
                <h3 className="mb-1  font-weight-semibold red-color">4Sight File</h3>
                <div className="d-flex align-items-center">
                  <p className="font-poppins text-muted mt-3 mb-0">Jan 20, 2021</p>
                  <p className="font-poppins mt-3 mb-0 font-weight-bold text-dark-80 ml-auto">780 MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recent-files-table">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="bg-white p-4 rounded mb-4">
                <div className="row p-md-2">
                  <div className="col-md-12">
                    <h5 className="font-poppins font-weight-semibold mb-4">Recent Files</h5>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th className="font-poppins">File Name</th>
                            <th className="font-poppins">Date</th>
                            <th className="font-poppins">Size</th>
                            <th className="font-poppins">Owner</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-pdf.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-jpg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-jpg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-pdf.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-jpg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-pdf.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-svg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-jpg.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                          <tr>
                            <td className="font-poppins"><img src="assets/images/file-pdf.svg" className="img-file ml-2 mr-4" alt="file" />4Sight File</td>
                            <td className="font-poppins">Jan 20, 2021</td>
                            <td className="font-poppins text-uppercase">700 MB</td>
                            <td className="font-poppins">Name</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-4 copy-right">
          <div className="col-12 ">
            <p className="text-center text-muted mb-0">Copyright © 2021. All rights reserved.</p>
          </div>
        </div>  
      </div>
    </div>
  </div>
  <div className="modal fade file-upload-modal" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <h5 className="modal-title font-weight-bold" id="staticBackdropLabel">Upload Files</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body mb-4">
          <div className="file-uploads-modal-d overflow-hidden position-relative">
            <input type="file" name="file-upload" />
            <div className="f-modal-attchment d-flex align-items-center justify-content-center">
              <img src="assets/images/cloud-data-upload.svg" className="cloud-data-upload w-30 mr-2" alt="cloud-data-upload-img" /> 
              <p className="attchment-content mb-0 font-weight-medium font-poppins text-muted">Drag and drop or <span>browse files</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

        </>
    )
}

export default MyFile
