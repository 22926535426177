import React,{ useEffect } from 'react'
import ToggleBar from '../../common/Util';
import {Link} from 'react-router-dom'
function Navbar() {
  useEffect(()=>{
    ToggleBar()
    },[])
 return (
 <>
 <div className="position-sticky p-0 shadow z-index-1 bg-white px-sm-4 px-3">
  <div className="row mr-lg-0">
    <div className="col-md-12">
      <div className="navbar-custom   position-relative d-sm-flex align-items-center  pb-sm-0 pb-3">
        <Link to="/" className="d-md-none mr-3"><img src="assets/images/logo-sm.png" className="img-fluid logo-mob-icon" alt="img" /></Link>
        <button type="button" className="top-togglebar bg-transparent border-0 mt-sm-0 mt-3">
          <img src="assets/images/menu.svg" className="img-fluid" alt="img" />
        </button>
        <div className="app-search position-relative  ml-sm-4  mt-sm-0 mt-3">
          <form role="search">
            <a href="#!" className="search-icon position-absolute  rounded-circle d-flex align-items-center justify-content-center h-100">
              <img src="assets/images/search-icon.svg" className="img-fluid w-18" alt="img" /></a>
            <input type="text" id="AllCompo" placeholder="Search" className="form-control ui-autocomplete-input  border px-5 border-0 search-input" autoComplete="off" />
          </form>
        </div>
        <div className="ml-auto user-profile-name">
          <ul className="list-unstyled mb-0">
            <li className="list-inline-item mx-2 mb-0" />
            <li className="list-inline-item user-setting opacity-half">
              <div className="dropdown profile-detail dashboard-profile py-1 pr-sm-3">
                <a className="dropdown-toggle mr-2" href="#!" id="dropdown03" data-toggle="dropdown"  aria-expanded="true">
                  <span className="d-none d-sm-inline-block pr-2 font-poppins">Ricky Smith</span>
                </a>
                <div className="dropdown-menu d-none " aria-labelledby="dropdown03">
                  {/*  <a class="dropdown-item mb-1" href="#"><img src="images/user.svg" class="img-fluid mr-2" alt="img"> Profile</a>
                                          <a class="dropdown-item mb-1" href="#"><img src="images/wallet.svg" class="img-fluid mr-2" alt="img"> My Wallet</a>
                                          <a class="dropdown-item mb-1" href="#"> <img src="images/settings.svg" class="img-fluid mr-2" alt="img">Settings</a>
                                          <a class="dropdown-item mb-1" href="#"> <img src="images/lock-p.svg" class="img-fluid mr-2" alt="img">Lock screen</a>
                                          <div class="dropdown-divider"></div>
                                          <a class="dropdown-item" href=""><img src="images/logout.svg" class="img-fluid mr-2" alt="img"> Logout</a> */}
                </div>
              </div></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

</>
    )}

    export default Navbar;