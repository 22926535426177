import React,{useEffect} from 'react'
import './App.css';
import Dashboard from './user/components/Dashboard';
import Sidebar  from './user/components/Sidebar';
import {BrowserRouter  as Router, Route, Switch } from 'react-router-dom';
import Overview from './user/components/Overview';
import Login from './common/Login'
import ToggleBar from './common/Util'
import MyFile from './user/components/MyFile';
import LaunchMonitor from './user/components/LaunchMonitor';
import InsightMiner from './user/components/InsightMiner';
import KeyDriver from './user/components/KeyDriver';
import Logout from './common/Logout';
function App() {
  useEffect(()=>{
  ToggleBar();
  },[])
  
  return ( 
    <Router>
      <Switch> 
        <>
 <Route exact path="/login" component={Login}></Route>
 <Route exact path='/logout' component={Logout}/>
 <div className="wrapper overflow-hidden">
        <div className="d-flex">
        <Sidebar/>
            <Route exact path="/" component={Dashboard}></Route>
            <Route exact path="/overview" component={Overview}></Route>
            <Route exact path='/myfiles' component={MyFile}></Route>
            <Route exact path="/launch-monitor" component={LaunchMonitor}></Route>
            <Route exact path="/insight-miner" component={InsightMiner}></Route>
            <Route exact path="/key-drivers" component={KeyDriver}></Route>
      </div>
      </div> 
      </>
      </Switch>
    
    </Router>  
 
  );
}

export default App;
