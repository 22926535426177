import React,{useState} from 'react'
import { useHistory } from "react-router-dom"
import axios from 'axios';

export default function Login() {
  let history=useHistory()
  const[state,setState]=useState({
        
    email:"",
    password:""
})
const[errors,setError]=useState({
   
    email:"",
    password:""
})

const handleChange=(e)=>{
    const{id,value}=e.target;
  
   if(id==="email")
   {
       setError(prevState=>({...prevState,[id]: value.length > 30 ? "email can not be more than 30 character" : ""}));
       let apos = value.indexOf("@");
       let dotpos = value.lastIndexOf(".");
       if (apos < 1 || dotpos - apos < 2)
       {
           setError(prevState=>({...prevState,[id]:"please enter a valid email id"}));
       }
   }
   if(id==="password")
   {
    setError(prevState => ({
        ...prevState,
        [id] : value.length < 6 ? "password must contain atleast  6 character" : ""
    }))
   }
    setState(prevState=>({...prevState,[id]:value}))
}


const handleSubmit=(e)=>{
  e.preventDefault();
 
   if (state.email === "") {
      setError( {email:"Can not be null"});
    } else if (state.password === "") {
      setError({password:"Can not be null"}
      );
    }   else if (errors.email.length !== 0) {
      setError( errors.email);
    } else if (errors.password.length !== 0) {
      setError(errors.password);
    } 
else{
    axios
    .post("/api/users/login", state)
    .then((res) => {
        console.log(res.data);
        localStorage.setItem("login", res.data.token);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("name", res.data.name); 
        //console.log(localStorage.getItem("login"))
      history.push("/");
      
    })
  
    .catch((error) => {
      console.log(error);
     // setAlert("failed");
    });
  }
    setState({email:"",password:""});
}
    

    return (
        <>
       <div className="login-bg">
  <div className="d-flex align-items-center justify-content-center h-100vh-md">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-12">
          <div className="login-section mx-auto">
            <div className="text-center">
              <img src="../assets/images/login-logo.png" className="img-fluid mb-4" alt="login-logo" />
            </div>
            <div className="bg-white py-5 px-4 rounded shadow">
              <h4 className="font-poppins mb-0 px-3">Login to your <b>4Sight</b></h4>
              <p className="mb-3 mt-2 px-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. </p>
              <form className="login-form"  onSubmit={handleSubmit}>
                <div className="form-group px-3">
                  <input   type="text"
                id="email"
                placeholder="Email"
                value={state.email}
               onChange={handleChange} 
               className="form-control rounded mb-3 bg-gray border-0 py-3" />
                </div>
                {errors.email ? (
                <div className="alert alert-danger m-3 px-3" role="alert">
                  {errors.email}
                </div>
              ) : (
                ""
              )} 
                <div className="form-group px-3">
                  <input   type="password"
                id="password"
                placeholder="password"
                value={state.password}
               onChange={handleChange}
                className="form-control rounded mb-3 bg-gray border-0 py-3" />
                </div>
                {errors.password ? (
                <div className="alert alert-danger m-3 px-3" role="alert">
                  {errors.password}
                </div>
              ) : (
                ""
              )} 
                <div className="form-check my-4 px-3">
                  <input type="checkbox" className="form-check-input keep-sign" id="exampleCheck1" />
                  <label className="form-check-label text-muted " htmlFor="exampleCheck1">Keep me signed in</label>
                </div>
                <div className="px-3">
                {/*   <button type="button" className="btn btn-dark font-poppins font-poppins">Login</button> */}
                <input
                 type="submit"
                 value="Login"
                 className="btn btn-dark font-poppins font-poppins"
                />
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}
