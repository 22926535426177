import $ from 'jquery';


function ToggleBar(){
    $(".top-togglebar").click(function(){
        //  alert('hi');
         $("body").toggleClass("top-bar-expand");
      }); 
}




export default ToggleBar;