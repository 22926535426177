import React from 'react'
import Navbar from './Navbar';
//import axios from 'axios';
import Rating from '../components/Rating'
//import LineChart from '../components/LineChart'
export default function Dashboard() {
/* 
   const[state,setState]=useState([]);
   useEffect(()=>{
      axios.get('http://localhost:4001/api/analysis/review').then((res)=>{
         setState(res.data);
         console.log(res.data);
      }).catch((err)=>{
         console.log(err)
      })
   },[]) */

return (
<>  
<div className="page-wrapper position-relative overflow-hidden">
   <Navbar/>
   <div className="page-content-tab p-sm-4 p-3 overflow-auto">
      
      <div className="container-sm px-0">
         <div className="row">
            <div className="col-lg-4 col-md-12 mb-lg-0 mb-4 pt-sm-0 pt-4">
               <div className="bg-white shadow h-100 rounded p-4">
                  <div className="p-md-2 text-center">
                     <div className="d-flex align-items-start justify-content-center">
                        <img src="assets/images/dash1-welcome-check-img.jpg" className="img-fluid" alt="welcome" />
                     </div>
                     <div className="mt-2">
                        <h4 className="font-poppins font-weight-medium text-welcome">Welcome back Jane !</h4>
                        <img src="assets/images/dash-welcome-check-img.jpg" className="img-fluid" alt="welcome-check" />
                        <p className="text-muted mb-0 mt-2">Urinary Launch is <span className="text-success-o font-weight-semibold">On Track</span></p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-lg-8 col-12 mb-lg-0 mb-4">
               <div className="row">
                  <div className="col-md-6 mb-4">
                     <div className="bg-white shadow p-4 rounded h-100">
                        <div className="d-flex w-100 p-md-2 welcome-graph-detail">
                           <div>
                              <h6 className="text-uppercase mb-0">Average star rating</h6>
                            
                              <div className="reviews my-3">
                                 <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                                 <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                                 <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                                 <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                                 <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              </div>
                              <div className="d-flex align-items-center pt-2">
                                 <img src="assets/images/up-arrow.png" className="img-fluid" alt="up-arrow" />
                                 <img src="assets/images/down-arrow.png" className="img-fluid d-none" alt="up-arrow" />
                                 <h6 className="font-weight-semibold text-muted mb-0 pl-2">10% <span className="text-success-o">Increase</span> from last month</h6>
                              </div>
                           </div>
                           <div className="ml-auto mb-3 mb-sm-0">
                              <div className="w-55 h-55">
                                 <img src="assets/images/star-img.png" className="img-fluid" alt="star" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 mb-4">
                     <div className="bg-white shadow p-4 rounded h-100">
                        <div className="d-flex w-100 p-md-2 welcome-graph-detail">
                           <div >
                              <h6 className="text-uppercase mb-0">Reviews</h6>
                              <h3 className="font-weight-semibold my-3 color-light-black">1,285</h3>
                              <div className="d-flex align-items-center">
                                 <img src="assets/images/up-arrow.png" className="img-fluid" alt="up-arrow" />
                                 <img src="assets/images/down-arrow.png" className="img-fluid d-none" alt="up-arrow" />
                                 <h6 className="font-weight-semibold text-muted mb-0 pl-2">28% <span className="text-success-o">Increase</span> from last month</h6>
                              </div>
                           </div>
                           <div className="ml-auto mb-3 mb-sm-0">
                              <h3><span className="badge badge-primary p-0 rounded-circle w-45 h-45 d-flex align-items-center justify-content-center mt-3">New</span></h3>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 mb-lg-0 mb-4">
                     <div className="bg-white shadow p-4 rounded h-100">
                        <div className="d-flex w-100 p-md-2 welcome-graph-detail">
                           <div>
                              <h6 className="text-uppercase mb-0">Social Media</h6>
                              <h3 className="font-weight-semibold my-3 color-light-black">385 Mentions</h3>
                              <div className="d-flex align-items-center">
                                 <img src="assets/images/up-arrow.png" className="img-fluid" alt="up-arrow" /> 
                                 <img src="assets/images/down-arrow.png" className="img-fluid d-none" alt="up-arrow" />
                                 <h6 className="font-weight-semibold text-muted mb-0 pl-2">11% <span className="text-success-o">Increase</span> from last month</h6>
                              </div>
                           </div>
                           <div className="ml-auto mb-3 mb-sm-0">
                             <div className="w-55 h-55">
                                 <img src="assets/images/comment-img.png" className="img-fluid" alt="comment" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-md-6">
                     <div className="bg-white shadow p-4  rounded h-100">
                        <div className="d-flex w-100 p-md-2 welcome-graph-detail">
                           <div>
                              <h6 className="text-uppercase mb-0">Sentiment</h6>
                              <h3 className="font-weight-semibold my-3 color-light-black">57% Positive</h3>
                              <div className="d-flex align-items-center">
                                 <img src="assets/images/up-arrow.png" className="img-fluid d-none" alt="up-arrow" />
                                 <img src="assets/images/down-arrow.png" className="img-fluid" alt="up-arrow" />
                                 <h6 className="font-weight-semibold text-muted mb-0 pl-2">4% <span className="text-danger">Decrease</span> from last month</h6>
                              </div>
                           </div>
                           <div className="ml-auto mb-3 mb-sm-0">
                              <div className="bg-danger rounded-circle w-55 h-55 d-flex align-items-center justify-content-center">
                                 <img src="assets/images/affilate.svg" className="w-35 img-fluid" alt="affilate" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-12 mt-4 mb-lg-0 mb-4">
               <div className="bg-white shadow p-4 rounded">
                  <div className="p-md-2 graph-image-con">
                  {/*    <div className="d-sm-flex w-100 align-items-center">
                        <h4 className="font-weight-medium font-poppins text-exp ">Average Star Rating</h4>
                        <a href="#!" className="ml-auto text-primary">View More</a>
                     </div>
                     <h6 className="text-muted font-weight-semibold">16 Nov,2020 - 15 Dec,2020</h6> */}
             {/*      <img src="assets/images/graph-1.jpg" className="graph mt-5" alt="graph" /> */}
                    <Rating/>
                  </div>
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="bg-white shadow p-4 rounded mt-4">
                  <h4 className="font-weight-medium font-poppins text-exp p-md-2">Project Tracker</h4>
                  <ul className="list-unstyled mb-0 notification-ul mt-2 p-md-2">
                     <li className="bg-secondary position-relative first-notification-li">
                        <div className="bg-white notification-li-detail h-100 position-relative">
                        </div>
                     </li>
                     <li className="bg-danger position-relative counter-danger">
                        <div className="bg-white notification-li-detail h-100 position-relative">
                           <h6 className="line-height-1"><small>01/25/21</small></h6>
                           <h6 className="mb-0 line-clamp-2">Analysis loaded into Dashboard {/* <u className="text-warning"> consectetur adipisicing </u>  dolor sit telit, ... */}</h6>
                        </div>
                     </li>
                     <li className="bg-info position-relative counter-info">
                        <div className="bg-white notification-li-detail  h-100">
                           <h6 className="line-height-1"><small>01/20/21</small></h6>
                           <h6 className="mb-0 line-clamp-2">Initiated Data discovery and normalization {/* <u className="text-warning"> consectetur adipisicing </u>  dolor sit telit, ... */}</h6>
                        </div>
                     </li>
                     <li className="bg-parrot position-relative counter-parrot">
                        <div className="bg-white notification-li-detail  h-100">
                           <h6 className="line-height-1"><small>01/19/21</small></h6>
                           <h6 className="mb-0 line-clamp-2">Loaded Social Media  {/* <u className="text-warning"> consectetur adipisicing </u>  dolor sit telit, ... */}</h6>
                        </div>
                     </li>
                     <li className="bg-secondary position-relative counter-secondary">
                        <div className="bg-white notification-li-detail  h-100">
                           <h6 className="line-height-1"><small>01/18/21 </small></h6>
                           <h6 className="mb-0 line-clamp-2"> Loaded Chewy and Amazon reviews {/* <u className="text-warning"> consectetur adipisicing </u>  dolor sit telit, ... */}</h6>
                        </div>
                     </li>
                     <li className="bg-purple position-relative counter-purple">
                        <div className="bg-white notification-li-detail  h-100">
                           <h6 className="line-height-1"><small>01/15/21</small></h6>
                           <h6 className="mb-0 line-clamp-2">Identified Royal Canin data sources {/* <u className="text-warning"> consectetur adipisicing </u>  dolor sit telit, ... */}</h6>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="bg-white shadow p-4 rounded mt-4">
                  <div className="review-comments-con-outer">
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">Helped my golden to lose weight</h6>
                           <p>The vet recommended this food for diet and exercise. The food is less than 300 kcal/cup. Dog loves it and is acting more active than before because he is losing weight and getting more exercise. When he loses his 10lbs, I will mix his older kibble in as a ratio of 1 to 3 cups of this gastro food, while maintaining his 5 miles of walks per day.</p>
                          {/*  <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">Too expensive for small bag</h6>
                           <p>Very good product but this small bag of dog food is insanely expensive.</p>
                         {/*   <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">My Little Dog Jacob is eating it, First time in 10 years he has eaten Kibble!!</h6>
                           <p>Vet said if I wanted him to live he must be on a low fat diet, first tried Hills, he would not touch it. He likes this mixed wit Royal Canin wet food!</p>
                           {/* <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">Solved all problems from my three boxers; yes!!!!!</h6>
                           <p>Let me start by saying I was one of the worst offenders for being 100% against my dogs eating Hill's Science Diet. I was told that it contained lots of toxic chemicals and was only pushed on consumers by vets because they were paid by Hill's to push the product. My opinion has since changed since using this product. I have three boxers. One is in perfect health, the other suffers from terrible skin allergies, and the last has a very sensitive stomach due a stomach flip he had surgery for when his previous owners fed him a sock with food on it to try and kill him -- because of this, he has terrible farts.

Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.

While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).

Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p>
                           {/* <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">My vet recommends this over all other brands. Highly recommend.</h6>
                           <p>This is what my vet (and other vets I’ve had through the years) recommends over all other brands. My 3 dogs have thrived on it and have lived long, healthy lives. Highly recommend.</p>
                           {/* <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                     <div  className="review-comments-con d-inline-block w-100">
                        <div className="d-flex align-items-center mb-4">
                           <img src="assets/images/user.jpg" className="img-fluid user-review-profile" alt="user" />
                           <div className="reviews ml-4">
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                              <img src="assets/images/review.svg" className="img-fluid w-18" alt="review" />
                           </div>
                        </div>
                        <div className="review-comment-area">
                           <h6 className="font-weight-semibold">Very confused on ingredients</h6>
                           <p>After all the talk about some foods deleting taurine from their ingredients, I made it a point to buy the “small adult” food that advertises that this is in the ingredients. I rec’d the bag of food and no such mention of this supplement. This is very important to me as I just lost my last beloved shih tzu with congestive heart failure and taurine was a very vital ingredient in his diet. I am also confused as to the size dog this food is for. Some areas it says this product is for very small dogs and in other areas it says up to 22 lbs. my dog is a rescue do and we thing around 7/8 years okd, weighing 19 lbs. so tell me what is is I should be feeding my dog with this all in mind. Do I call Royal Canin direct or deal with this with amazon. My real disappointment is that I searched a very long tine to try to find the proper diet for my dog needing the taurine per my vet. I do hope to hear from you</p>
                           {/* <p>Since I've been using this product (close to a month now), my allergy dog no longer scratches herself all day and my little tooter dog no longer farts. I am literally amazed. We tried everything from Taste of the Wild, Blue Buffalo, Royal Canin, Nutrish, etc. and nothing has worked for out dogs but this product. I cannot even begin to say how thankful I am we tried this food.</p>
                           <p>While I understand all vets have different opinions, but vet advised my husband and I to steer clear of "grain-free" foods. This really surprised me, because I always thought that they would be healthier for my pups and would prolong their life. Apparently, this is not the case. Through extensive research, they are finding that these types of foods are actually limiting the life of pup's due to the higher fat contents, which in turn wears down their heart much quicker (apologies if I didn't explain this correctly; trying to parrot what my vet said).</p>
                           <p>Moving forward, I'll be using this product and would not hesitate to recommend it to other pet owners who experience the same issues with their pups as I do.</p> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="row py-4 copy-right">
         <div className="col-12 ">
            <p className="text-center text-muted mb-0">Copyright © 2021. All rights reserved.</p>
         </div>
      </div>
   </div>
</div>
</>
)
}