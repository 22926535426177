import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
//import axios from "axios";
import data from '../../dum.json'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

// usage example:
//var a = ['a', 1, 'a', 2, '1'];
const Rating = () => {
  const [chartData, setChartData] = useState({});
//  const [employeeSalary, setEmployeeSalary] = useState([]);
  //const [employeeAge, setEmployeeAge] = useState([]);

  const chart = () => {
    let yearMonth = [];
    let avg_rating = [];
     for (const dataObj of data) {
      yearMonth.push(parseInt(dataObj.YearMonth));
    avg_rating.push(parseFloat(dataObj.avg_star_rating));
  }
  let unique = yearMonth.filter(onlyUnique);
  setChartData({
    labels:  unique,
    datasets: [
      {
        label: "star rating",
        data: avg_rating ,
        backgroundColor: "rgba(245,245,255)",
        borderWidth: 3,
        borderColor:'light-black'
      }
    ]
  });
 
/* 
     axios
      .get(`/api/analysis/review`)
      .then(res => { */
        //console.log(res.data[0].YearMonth.split('-')[0]);
        /*  let ar=[0,0,0,0,0,0,0,0];
         let cr=[1,1,1,1,1,1,1,1];
        for(var i = 0; i < res.data.length; i++)
        {
          if(res.data[i].YearMonth.split('-')[0]==="2013")
          {
            
              ar[0]+=res.data[i].avg_star_rating
              cr[0]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2015")
          {
            ar[2]+=res.data[i].avg_star_rating
            cr[2]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2014")
          {
            ar[1]+=res.data[i].avg_star_rating
            cr[1]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2016")
          {
            ar[3]+=res.data[i].avg_star_rating
            cr[4]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2017")
          {
            ar[4]+=res.data[i].avg_star_rating
            cr[4]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2018")
          {
            ar[5]+=res.data[i].avg_star_rating
            cr[5]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2019")
          {
            ar[6]+=res.data[i].avg_star_rating
            cr[6]+=1
          }
          else if(res.data[i].YearMonth.split('-')[0]==="2020")
          {
            ar[7]+=res.data[i].avg_star_rating
            cr[7]+=1
          }
        }
        console.log(ar) 
        console.log(cr) 
        
        let rating=[];
        for( let v=0;v<ar.length;v++)
        {
                rating.push(parseFloat(ar[v]/cr[v]))
        }
 */

//console.log(rating)
        /* for (const dataObj of res.data) {
          
            yearMonth.push(parseInt(dataObj.YearMonth));
          avg_rating.push(parseFloat(dataObj.avg_star_rating));
        }

        let unique = yearMonth.filter(onlyUnique);
      

        setChartData({
          labels:  unique,
          datasets: [
            {
              label: "star rating",
              data:avg_rating ,
              backgroundColor: 'rgba(245,245,255)',
              borderWidth: 3,
              borderColor:'light-black'
            }
          ]
        });
      })
      .catch(err => {
        console.log(err);
      }); */
    
  }; 

  useEffect(() => {
    chart();
  }, []);
  return (
    <>
      <h4 className="font-weight-medium font-poppins text-exp ">Average Star Rating</h4>
      <h6 className="text-muted font-weight-semibold">16 Nov,2020 - 15 Dec,2020</h6>
      <div>
        <Line
          data={chartData}
          options={{
            responsive: true,
           // title: { text: "THICCNESS SCALE", display: true },
            scales: {
              yAxes: [
                {
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                    beginAtZero: true
                  },
                  gridLines: {
                    display: false
                  }
                }
              ],
              xAxes: [
                
                {
                 
                  gridLines: {
                    display: false
                  }
                }
              ]
            }
          }}
        />
      </div>
    </>
  );
};

export default Rating;
