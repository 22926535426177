import React,{useEffect} from 'react';
import {Link} from 'react-router-dom'
import Toggle from '../../common/LaunchToggle';
import ToggleBar from '../../common/Util';
function LaunchMonitor() {
    useEffect(()=>{
        ToggleBar()
        Toggle()
    },[])
    return (
       <>
       <div className="page-wrapper position-relative overflow-hidden">
  <div className="position-sticky p-0 shadow z-index-1 bg-white px-sm-4 px-3">
    <div className="row mr-lg-0">
      <div className="col-md-12">
        <div className="navbar-custom   position-relative d-flex align-items-center">
          <Link to="/" className="d-md-none mr-3"><img src="assets/images/logo-sm.png" className="img-fluid logo-mob-icon" alt="img" /></Link>
          <button type="button" className="top-togglebar bg-transparent border-0">
            <img src="assets/images/menu.svg" className="img-fluid" alt="img" />
          </button>
          <div className="theme-custom-select position-relative ml-3 diable-enable-select border-0 brand-dropdown">
            <select className="form-control  border-0">
              <option className="py-4" value="alert type">Royal Canin</option>
              <option className="py-4" value="alert">Zevo</option>
            </select>
          </div>
          <div className="ml-auto">
            <ul className="list-unstyled mb-0 ">
              <li className="list-inline-item mx-2 d-sm-inline-block d-none"><img src="assets/images/royal-chain.jpg" className="img-fluid" alt="royal" /></li>
              <li className="list-inline-item ml-2">
                <div className="dropdown  profile-detail py-1 pr-sm-3">
                  <a className="dropdown-toggle mr-2" href="#!" id="dropdown03" data-toggle="dropdown" /* aria-haspopup="true" */ aria-expanded="true">
                    <img src="assets/images/user.jpg" className="img-fluid rounded-circle user-name mx-2" alt="img" />
                    <span className="d-none d-sm-inline-block pr-2 font-poppins">Amella</span>
                  </a>
                  <div className="dropdown-menu d-none " aria-labelledby="dropdown03">
                    {/*  <a class="dropdown-item mb-1" href="#"><img src="images/user.svg" class="img-fluid mr-2" alt="img"> Profile</a>
                                       <a class="dropdown-item mb-1" href="#"><img src="images/wallet.svg" class="img-fluid mr-2" alt="img"> My Wallet</a>
                                       <a class="dropdown-item mb-1" href="#"> <img src="images/settings.svg" class="img-fluid mr-2" alt="img">Settings</a>
                                       <a class="dropdown-item mb-1" href="#"> <img src="images/lock-p.svg" class="img-fluid mr-2" alt="img">Lock screen</a>
                                       <div class="dropdown-divider"></div>
                                       <a class="dropdown-item" href=""><img src="images/logout.svg" class="img-fluid mr-2" alt="img"> Logout</a> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="page-content-tab p-4 overflow-auto">
  <div className="row mb-3 align-items-center">
        <div className="col">
          <ul className="breadcrumb  pl-md-3 pl-0 list-unstyled mb-0 bg-transparent">
            <li className="h5 mb-0 font-poppins"><a href="#!">Royal Canin</a></li>
            <li><img src="assets/images/right-arrow.svg" className="img-fluid breadcrumb-arrow" alt="arrow" /></li>
            <li className="h5 mb-0 font-poppins"><a href="#!" className="font-weight-semibold">Launch Monitor</a></li>
          </ul>
        </div>
      
      </div>
    <div className="px-0">
      <div className="row">
        <div className="col-md-5 d-flex">
          <div className="bg-white p-4 w-100 launch-moniter-col">
            <h5 className="font-weight-bold">Launch moniter Progress</h5>
            <p>
              <span className="pr-2 font-weight-semibold">Current Status:</span>
              <span className="text-primary font-weight-semibold" style={{display: 'none'}}>Too Early to Tell</span>
              <span className="text-success-o font-weight-semibold">on Track</span>
              <span className="text-warning font-weight-semibold" style={{display: 'none'}}>Needs Attention</span>
              <span className="text-danger font-weight-semibold" style={{display: 'none'}}>Off track</span>
            </p>
            <div className="row mt-5">
                <div className="col-md-5 pr-4 text-center">
                  <div className="d-flex justify-content-between">
                    <span className="badge badge-pill badge-default-cus px-4 col-3 mr-2">&nbsp;</span>
                    <span className="badge badge-pill badge-default-cus px-4 col-3 mr-2">&nbsp;</span>
                    <span className="badge badge-pill badge-default-cus px-4 col-3">&nbsp;</span>
                  </div>
                  <p className="badge-text font-weight-semibold mt-1">Too Early to Tell</p>
                </div>
               <div className="col-md-7">
                <div className="row">
                 <div className="col-md-4 text-center">
                    <div className="d-flex justify-content-between">
                      <span className="badge badge-pill badge-success-cus px-4 mx-auto">&nbsp;</span>
                    </div>
                     <p className="badge-text font-weight-semibold mt-1">On track</p>
                 </div>
                 <div className="col-md-4 text-center px-0">
                  <div className="d-flex justify-content-between">
                    <span className="badge badge-pill badge-default-cus px-4 mx-auto">&nbsp;</span>
                  </div>
                  <p className="badge-text font-weight-semibold mt-1">Needs Attention</p>
                </div>
                <div className="col-md-4 text-center">
                  <div className="d-flex justify-content-between">
                    <span className="badge badge-pill badge-default-cus  px-4">&nbsp;</span>
                  </div>
                  <p className="badge-text font-weight-semibold mt-1">Off track</p>
                </div>
              </div>
              </div>
            </div>
    
            <h6 className="mb-4 mt-5">We identified 3 main drivers</h6>
            <ul className="list-unstyled driver-list mb-4">
              <li className="mb-2"><span className="driver-icons"><img src="assets/images/hastage.svg" className="img-fluid" alt="img" width="15px" /></span><b>350</b> mentions using hastag <b>#royalcanin</b></li>
              <li className="mb-2"><span className="driver-icons"><img src="assets/images/chain.svg" className="img-fluid" alt="img" width="13px" /></span><b>125 new </b>reviews from last week</li>
              <li className="mb-2"><span className="driver-icons"><img src="assets/images/hastage.svg" className="img-fluid" alt="img" width="15px" /></span><b>#10 from #56</b> in Google Search Rank</li>
            </ul>
            <h6 className="mt-5">Related Topics</h6>
            <h6 className="font-weight-semibold">#royalcanindogs, #alwayshungry, #teamdog, #royalcaninpuppies
</h6>
          </div>
        </div>
        <div className="col-md-7 d-flex">
          <div className="bg-white p-4 w-100">
            <div className="d-flex">
              <h5 className="font-weight-bold" id="performance-heading">Performance Metrics</h5>
              <h5 className="font-weight-bold" id="qualitative-heading" style={{display: 'none'}}>Qualitative Data View</h5>
              <div className="ml-auto d-flex">
                <div className="custom-control custom-switch info-switch performance-tab-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                  <label className="custom-control-label" htmlFor="customSwitch1" />
                </div>
              </div>
            </div>
            <div className="w-100" id="performance-tab-one">
                  <div className="row mt-5">
                      <div className="col-md-6">
                          <h6 className="font-weight-semibold text-center mb-4 color-light-black">Consumer Experience</h6>
                          <div className="row">
                              <div className="col-md-6">
                                 <h3 className="font-weight-semibold color-light-black">4.0 <span className="h6 font-weight-bold">Avg Stars</span></h3> 
                                 <p>5% <span className="text-danger font-weight-semibold">under</span> index</p>
                              </div>
                               <div className="col-md-6">
                                 <h3 className="font-weight-semibold color-light-black">1285 <span className="h6 font-weight-bold">Reviews</span></h3> 
                                 <p>11% <span className="text-success-o font-weight-semibold">over</span> Index</p>
                              </div>
                          </div>
                          <div className="chart text-center">
                              <img src="assets/images/line-chart-img.png" className="img-fluid mt-4 mx-auto" alt="graph" />
                          </div>
                          <h5 className="mt-4 text-center font-weight-semibold">Star Rating</h5>
                      </div>
                      <div className="col-md-6 border-left">
                          <h6 className="font-weight-semibold text-center mb-4 color-light-black">Consumer Perception</h6>
                          <div className="row">
                              <div className="col-md-6">
                                 <h3 className="font-weight-semibold color-light-black">4.0 <span className="h6 font-weight-bold">Avg Stars</span></h3> 
                                 <p>5% <span className="text-danger font-weight-semibold">under</span> index</p>
                              </div>
                               <div className="col-md-6">
                                 <h3 className="font-weight-semibold color-light-black">1285 <span className="h6 font-weight-bold">Reviews</span></h3> 
                                 <p>11% <span className="text-success-o font-weight-semibold">over</span> Index</p>
                              </div>
                          </div>
                          <div className="chart text-center">
                              <img src="assets/images/pie-chart-img.png" className="img-fluid mt-4 mx-auto" alt="graph" />
                          </div>
                      </div>
                  </div>
            </div>

            <div className="w-100" id="performance-tab-two" style={{display: 'none'}}>
                  <div className="row mt-5">
                      <div className="col-md-6">
                          <h6 className="font-weight-semibold text-center mb-4 color-light-black">Consumer Experience</h6>
                          <div className="chart text-center">
                              <img src="assets/images/micro-theme-chart-one.png" className="img-fluid mt-4 mx-auto" alt="graph" />
                          </div>
                      </div>
                      <div className="col-md-6 border-left">
                          <h6 className="font-weight-semibold text-center mb-4 color-light-black">Consumer Perception</h6>
                          <div className="chart text-center">
                              <img src="assets/images/micro-theme-chart-two.png" className="img-fluid mt-4 mx-auto" alt="graph" />
                          </div>
                      </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 d-flex">
          <div className="bg-white p-4 w-100">
            <h5 className="font-weight-bold text-capitalize">Frequently Used Words</h5>
            <hr />
            <div className="table-responsive top-driver-table frequently-table">
              <table className="w-100">
                <thead>
                    <tr>
                        <th>Word</th>
                        <th className="">Count</th>
                        <th className="text-center">WISR</th>
                    </tr>
                </thead>
                <tbody><tr>
                    <td>Excited</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Recommend</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Value</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Healthy</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Ingredients</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Quality</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                  <tr>
                    <td>Vet</td>
                    <td className="">67,849</td>
                    <td className="text-center">0.044539323</td>
                  </tr>
                </tbody></table>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <div className="bg-white p-4 w-100">
            <div className="d-flex align-items-center">
              <h5 className="font-weight-bold mb-0">Trending topics</h5>
              <ul className="nav nav-tabs border-0 mb-0 ml-auto" role="tablist">
                <li role="presentation" className="mr-3">
                  <a href="#positive" aria-controls="positive" role="tab" data-toggle="tab" className="active">
                    Positive
                  </a>     
                </li>
                <li role="presentation">
                  <a href="#negative" aria-controls="negative" role="tab" data-toggle="tab">
                    Negative
                  </a>
                </li>
              </ul>
            </div>
            <hr />
            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active" id="positive">
                <div className="bg-white  rounded">
                  <div className="table-responsive top-driver-table">
                    <table className="w-100">
                      <tbody><tr>
                          <td>Food Support</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Overall Satisfaction </td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Fit</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Price/Value for money</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Looks &amp; Design</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                        <tr>
                          <td>Color</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">+12%</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane" id="negative">
                <div className="bg-white  rounded">
                  <div className="table-responsive top-driver-table">
                    <table className="w-100">
                      <tbody><tr>
                          <td>Looks &amp; Design</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-danger">-12%</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-danger">-12%</td>
                        </tr>
                        <tr>
                          <td>Color</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-danger">-12%</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <div className="bg-white p-4 w-100">
            <div className="d-flex align-items-center">
              <h5 className="font-weight-bold mb-0">Liked/Disliked</h5>
              <ul className="nav nav-tabs border-0 mb-0 ml-auto" role="tablist">
                <li role="presentation" className="mr-3">
                  <a href="#like" aria-controls="like" role="tab" data-toggle="tab" className="active">
                    Top Liked
                  </a>     
                </li>
                <li role="presentation">
                  <a href="#dislike" aria-controls="dislike" role="tab" data-toggle="tab">
                    Top disliked
                  </a>
                </li>
              </ul>
            </div>
            <hr />
            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active" id="like">
                <div className="bg-white  rounded">
                  <div className="table-responsive top-driver-table">
                    <table className="w-100">
                      <tbody><tr>
                          <td>Comfort</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Overall Satisfaction</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Fit</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Price/value for money</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Look &amp; Design</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                        <tr>
                          <td>Color</td>
                          <td className="text-right text-secondary-o">67,899</td>
                          <td className="text-right text-success-o">12%</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane" id="dislike">
                <div className="table-responsive top-driver-table">
                  <table className="w-100">
                    <tbody><tr>
                        <td>Price/value for money</td>
                        <td className="text-right text-secondary-o">67,899</td>
                        <td className="text-right text-danger">12%</td>
                      </tr>
                      <tr>
                        <td>Look &amp; Design</td>
                        <td className="text-right text-secondary-o">67,899</td>
                        <td className="text-right text-danger">12%</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td className="text-right text-secondary-o">67,899</td>
                        <td className="text-right text-danger">12%</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td className="text-right text-secondary-o">67,899</td>
                        <td className="text-right text-danger">12%</td>
                      </tr>
                    </tbody></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-4 copy-right">
        <div className="col-12 ">
          <p className="text-center text-muted mb-0">Copyright © 2021. All rights reserved.</p>
        </div>
      </div>  
    </div>
  </div>
</div>

       </>
    )
}

export default LaunchMonitor
